import { useState } from "react";

import PageTitle from "../../components/page-title";

import NotificationTab from "./notification-tab";
import NotificationTableView from "./notification-table-view";
import NotificationListView from "./notification-list-view";
import NotificationsTopbar from "./notifications-topbar";

const viewTypeOptions = [
  { label: "List", value: "list" },
  { label: "Table", value: "table" },
];

const NotificationsPage = () => {
  const [viewType, setViewType] = useState(viewTypeOptions[0]);

  return (
    <div className="notifications_page_container">
      <PageTitle title="Notifications" />
      <NotificationsTopbar
        viewType={viewType}
        setViewType={setViewType}
        viewTypeOptions={viewTypeOptions}
      />
      <NotificationTab />
      {viewType.value === "list" ? (
        <NotificationListView />
      ) : (
        <NotificationTableView />
      )}
    </div>
  );
};

export default NotificationsPage;
