/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from "react";
import { useAppDispatch } from "../../redux";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { FileUploader } from "react-drag-drop-files";

import PageTitle from "../../components/page-title";
import Logo from "../../components/logo";
import FormInput from "../../components/form-input";
import Button from "../../components/button";
import Loader from "../../components/loader";

import {
  logoutUserAction,
  updateUserAction,
} from "../../redux/auth/user-slice";
import { getAISyncToVectorDBAction } from "../../redux/dashboard/settings-slice";
import { setAlert } from "../../redux/components/components-slice";

import Icon from "../../assets/svg";
import {
  shopifyLogo,
  woocommerceLogo,
  amazonLogo,
  walmartlogo,
  storeLogo,
} from "../../assets/img";
import { urlValidation } from "../../utils/functions";
import { didYouKnowTexts } from "../../utils/mockdata";

const OnboardingPage = () => {
  const dispatch = useAppDispatch();
  const { user } = useSelector((state: any) => state.userSlice);

  const [customStore, setCustomStore] = useState<{
    value: string;
    label: string;
  } | null>(null);
  const [shopData, setShopData] = useState<any>(null);
  const [file, setFile] = useState<File | null>(null);
  const [progress, setProgress] = useState(0);
  const [knowText, setKnowText] = useState(didYouKnowTexts[0]);
  const [knowTextCount, setKnowTextCount] = useState(0);
  const [aISyncCount, setAISyncCount] = useState(0);
  const [showAISyncError, setShowAISyncError] = useState(false);
  const [isSyncingStore, setIsSyncingStore] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const urlSearchString = window.location.search;
    const params = new URLSearchParams(urlSearchString);

    const step = params.get("st");
    const status = params.get("status");
    const errorMsg = params.get("error_msg");

    if (step && status === "success" && !errorMsg) {
      handleAISyncToVectorDB();
    } else if (errorMsg) {
      dispatch(setAlert(true, "error", errorMsg));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAISyncToVectorDB = async () => {
    setIsSyncingStore(true);

    await dispatch(getAISyncToVectorDBAction()).then((resp: any) => {
      if (resp?.isCompleted === true) {
        const newUser = { ...user, onboarded: true };
        dispatch(updateUserAction({ ...newUser }));
        setTimeout(() => window.location.assign("/home"), 10000);
      } else if (resp?.isCompleted === false) {
        reRunAISyncToVectorDB();
      } else {
        setShowAISyncError(true);
        setIsSyncingStore(false);
      }
    });
  };
  const reRunAISyncToVectorDB = async () => {
    if (aISyncCount > 2) {
      setShowAISyncError(true);
    } else {
      setTimeout(() => {
        setAISyncCount(aISyncCount + 1);
        handleAISyncToVectorDB();
      }, 3000);
    }
  };

  const handleChange = (name: string, value: string) => {
    setShopData({
      name,
      url: value,
    });
  };

  const extractStoreNameFromUrl = () => {
    var url = shopData?.url;
    var storeName = url
      ?.replace("https://", "")
      ?.replace("http://", "")
      ?.replace("www.", "")
      ?.replace(".myshopify.com", "");

    return storeName || "";
  };

  const connectShopifyStore = () => {
    const storeName = extractStoreNameFromUrl();
    const callbackUrl = `?callback=${window.location.href}&st=2&shop=shopify`;

    const redirectUrl = `https://api.konvas.ai/Authshopify/access/${storeName}/${user?.company_slug}/${callbackUrl}`;

    window.location.assign(redirectUrl);
  };

  const connectDummyStore = () => {
    setIsSubmitting(true);

    console.log(file);
    setTimeout(() => setProgress(33), 500);
    setTimeout(() => setProgress(66), 1500);
    setTimeout(() => setProgress(100), 2500);

    setTimeout(() => window.location.assign("/home"), 3000);
  };

  useEffect(() => {
    if (file) connectDummyStore();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  useEffect(() => {
    const totalIndex = didYouKnowTexts?.length - 1;
    const nextIndex = knowTextCount === totalIndex ? 0 : knowTextCount + 1;

    setTimeout(() => {
      setKnowText(didYouKnowTexts[nextIndex]);
      setKnowTextCount(nextIndex);
    }, 10000);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [knowText]);

  const stores = [
    {
      name: "shopify",
      icon: shopifyLogo,
      placeholder: "Enter Shopify Url (e.g. sample.shopify.com)",
      onClick: () => connectShopifyStore(),
    },
    {
      name: "woocommerce",
      icon: woocommerceLogo,
      placeholder: "Enter Woocommerce Url (e.g. sample.com)",
      onClick: () => {},
    },
    {
      name: "amazon",
      icon: amazonLogo,
      placeholder: "Enter Amazon Url (e.g. sample.com)",
      onClick: () => {},
    },
    {
      name: "walmart",
      icon: walmartlogo,
      placeholder: "Enter Walmart Url (e.g. sample.com)",
      onClick: () => {},
    },
  ];

  const customStores = [
    {
      value: "upload",
      label: "Upload Store Data",
    },
    {
      value: "connect",
      label: "Connect your Database",
    },
    {
      value: "integrate",
      label: "Integrate our API",
    },
  ];

  const fileTypes = ["JPG", "PNG", "JSON"];

  return (
    <React.Fragment>
      <PageTitle title="Onboarding" />

      <div className="page_container onboarding_page_container">
        <Logo />

        {isSyncingStore ? (
          <div className="generating_data">
            <p className="synching_store">
              <span>Please wait while we sync your store data...</span>
              <Loader />
              &#128161; {knowText}
            </p>
          </div>
        ) : showAISyncError ? (
          <div className="generating_data">
            <p className="synching_store">
              &#10071; Sorry, we encountered an error.
              <br />A customer success agent will contact you soon.
            </p>
          </div>
        ) : !isSubmitting ? (
          <>
            <div className="section">
              <p className="title">Add any of your Stores</p>

              <div className="stores">
                {stores?.map((item: any, i: any) => (
                  <div key={i} className="item">
                    <img src={item.icon} alt="Store logo" />

                    <div className="form">
                      <FormInput
                        type="text"
                        placeholder={item.placeholder}
                        onChange={(e: any) =>
                          handleChange(item.name, e?.target?.value)
                        }
                        readOnly={isSubmitting}
                      />

                      <Button
                        text="Submit"
                        disabled={urlValidation(shopData?.url)}
                        loading={isSubmitting}
                        onClick={item.onClick}
                      />
                    </div>
                  </div>
                ))}

                <p className="note">
                  You can add more stores later in Settings {">"} My stores.
                </p>
              </div>
            </div>

            <div className="section">
              <p className="title">Or a Custom Store</p>

              <div className="custom_stores">
                <img src={storeLogo} alt="Custom store logo" />

                <div className="list">
                  {customStores?.map((item: any, i: any) => (
                    <div
                      key={i}
                      className={classNames("item", {
                        selected: customStore?.value === item.value,
                      })}
                    >
                      <Button
                        leftIcon={item.value}
                        text={item.label}
                        disabled={isSubmitting}
                        onClick={() => setCustomStore(item)}
                      />

                      {item.value === customStore?.value &&
                        customStore?.value === customStores[0].value && (
                          <FileUploader
                            handleChange={(value: any) => setFile(value)}
                            name="file"
                            types={fileTypes}
                          >
                            <div className="file_uploader">
                              <Icon name="upload" />
                              <p>
                                <span>Click to upload</span> or drag and drop
                              </p>
                            </div>
                          </FileUploader>
                        )}
                    </div>
                  ))}
                </div>
              </div>

              <Button
                text="Logout"
                className="btn_tertiary_primary"
                onClick={() => dispatch(logoutUserAction())}
              />
            </div>
          </>
        ) : (
          <div className="generating_data">
            <p className="title">Generating initial data for your Store</p>

            <div className="progress_bar">
              <div className="value" style={{ width: `${progress}%` }}></div>
            </div>

            <p className="please_wait">Please Wait...</p>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default OnboardingPage;
