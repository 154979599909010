import Table from "../../components/table";

const NotificationTableView = () => {
  return (
    <div className="notification_table_container">
      <div className="table_container">
        <Table isLoading={false} headers={[]} noRecord={false}>
          <tr>
            <td>
              <div className="title">
                <input className="check-input" type={"checkbox"} />
                <p>You have created your first Segment</p>
              </div>
            </td>
            <td>
              <p className="content">
                Weldone! You just created your first segment. Weldone! You just
                .... <span>See More</span>
              </p>
            </td>
            <td>
              <p className="date">27th August 2024, 12:32pm</p>
            </td>
          </tr>
          <tr>
            <td>
              <div className="title">
                <input className="check-input" type={"checkbox"} />
                <p>You have created your first Segment</p>
              </div>
            </td>
            <td>
              <p className="content">
                Weldone! You just created your first segment. Weldone! You just
                .... <span>See More</span>
              </p>
            </td>
            <td>
              <p className="date">27th August 2024, 12:32pm</p>
            </td>
          </tr>
          <tr>
            <td>
              <div className="title">
                <input className="check-input" type={"checkbox"} />
                <p>You have created your first Segment</p>
              </div>
            </td>
            <td>
              <p className="content">
                Weldone! You just created your first segment. Weldone! You just
                .... <span>See More</span>
              </p>
            </td>
            <td>
              <p className="date">27th August 2024, 12:32pm</p>
            </td>
          </tr>
          <tr>
            <td>
              <div className="title">
                <input className="check-input" type={"checkbox"} />
                <p>You have created your first Segment</p>
              </div>
            </td>
            <td>
              <p className="content">
                Weldone! You just created your first segment. Weldone! You just
                .... <span>See More</span>
              </p>
            </td>
            <td>
              <p className="date">27th August 2024, 12:32pm</p>
            </td>
          </tr>
          <tr>
            <td>
              <div className="title">
                <input className="check-input" type={"checkbox"} />
                <p>You have created your first Segment</p>
              </div>
            </td>
            <td>
              <p className="content">
                Weldone! You just created your first segment. Weldone! You just
                .... <span>See More</span>
              </p>
            </td>
            <td>
              <p className="date">27th August 2024, 12:32pm</p>
            </td>
          </tr>
          <tr>
            <td>
              <div className="title">
                <input className="check-input" type={"checkbox"} />
                <p>You have created your first Segment</p>
              </div>
            </td>
            <td>
              <p className="content">
                Weldone! You just created your first segment. Weldone! You just
                .... <span>See More</span>
              </p>
            </td>
            <td>
              <p className="date">27th August 2024, 12:32pm</p>
            </td>
          </tr>
        </Table>
      </div>
    </div>
  );
};

export default NotificationTableView;
