import { Modal } from "reactstrap";

import SectionHeader from "../section-header";
import Button from "../button";

import Icon from "../../assets/svg";

type Props = {
  isOpen: boolean;
  close: any;
  campaignRecepients: string[];
};

const CampaignRecepientModal = ({
  isOpen,
  close,
  campaignRecepients,
}: Props) => {
  console.log("recepients:", campaignRecepients);
  return (
    <Modal className="campaign_recepient_modal" isOpen={isOpen}>
      <div className="modal_header">
        <SectionHeader
          leftSide={<div className="title_box">Campaign {">"} Recepients</div>}
          rightSide={
            <>
              <div className="icon" onClick={close}>
                <Icon name="close" />
              </div>
            </>
          }
        />
      </div>

      <div className="modal_body">
        <div className="campaign_recipients">
          <p className="title"> Recipients for this campaign:</p>

          <ol>
            {campaignRecepients.map((recepients) => {
              const nameEmailArray = recepients.split(";");
              const email = nameEmailArray[0];
              const name = nameEmailArray[1];
              return (
                <li key={recepients}>
                  <p>
                    {name}(<strong>{email}</strong>)
                  </p>
                </li>
              );
            })}
          </ol>
        </div>

        <Button className="btn_secondary" text="Close" onClick={close} />
      </div>
    </Modal>
  );
};

export default CampaignRecepientModal;
