import React, { useState } from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../redux";

import FormInput from "./form-input";
import DropDown from "./dropdown";
import FormSelect from "./form-select";
import Logo from "./logo";
import Tag from "./tag";

import { updateListLayoutAction } from "../redux/components/components-slice";

import { APP_LIST_LAYOUT_LIST, APP_LIST_LAYOUT_GRID } from "../utils/constants";

import Icon from "../assets/svg";
import { funnel, noStoreThumb } from "../assets/img";

import NotificationDropDown from "../dashboard/notifications/notification-dropdown";

type Props = {
  title: string;
  onSearch: (searchTerm: string) => void;
  activeBtnFilter?: string;
  filters?: {
    type: string;
    label?: string;
    variant?: string;
    value?: any;
    onChange?: (value: any) => void;
    onClick?: (value: any) => void;
    options?: any;
    children?: React.ReactNode;
  }[];
  actionBtn?: React.ReactNode;
  hideLayoutStyle?: boolean;
  hideAction?: boolean;
};

const Topbar = ({
  title,
  onSearch,
  activeBtnFilter,
  filters,
  actionBtn,
  hideLayoutStyle,
  hideAction,
}: Props) => {
  const dispatch = useAppDispatch();

  const { user } = useSelector((state: any) => state.userSlice);
  const { listLayout } = useSelector((state: any) => state.componentsSlice);

  const [searchTerm, setSearchTerm] = useState("");
  const [isSearching, setIsSearching] = useState(false);

  const handleSearch = () => {
    setIsSearching(true);

    onSearch(searchTerm);
    console.log(searchTerm, "searchTerm");

    setIsSearching(false);
  };
  const handleKeyPress = (event: any) => {
    if (event.key === "Enter" && searchTerm?.length > 1) {
      handleSearch();
    }
  };

  const dropdownItems = [
    { label: "Profile", action: () => {} },
    { label: "Settings", action: () => {} },
  ];

  const layoutOptions = [
    {
      icon: "list",
      onClick: () => dispatch(updateListLayoutAction(APP_LIST_LAYOUT_LIST)),
    },
    {
      icon: "grid",
      onClick: () => dispatch(updateListLayoutAction(APP_LIST_LAYOUT_GRID)),
    },
  ];

  const userAvatar = user?.thumb?.includes("no-photo")
    ? noStoreThumb
    : user?.thumb;

  return (
    <div className="topbar_container">
      <div className="top_section">
        <div className="title">
          {title === "Notifications" ? <Logo /> : <></>}
          <p>{title}</p>
        </div>

        <div className="flex_end">
          <FormInput
            type="text"
            placeholder={`Search ${title}`}
            value={searchTerm}
            onKeyPress={handleKeyPress}
            onChange={(e: any) => setSearchTerm(e?.target?.value)}
            readOnly={isSearching}
            leftIcon="search"
          />

          <DropDown
            toggler={
              <div className="notification">
                <Icon name="bell" />
                <div className="alert"></div>
              </div>
            }
          >
            <NotificationDropDown />
          </DropDown>

          <div className="user">
            <img src={userAvatar} alt="" className="avatar" />

            <div className="info">
              <p className="name">{user?.user_name}</p>
              <p className="role">{user?.company_name}</p>
            </div>

            <DropDown toggler={<Icon name="chevronDown" />}>
              {dropdownItems?.map((item: any, i: any) => (
                <div key={i} className="dropdown-item" onClick={item?.action}>
                  {item?.label}
                </div>
              ))}
            </DropDown>
          </div>
        </div>
      </div>

      {((filters && filters?.length > 0) || actionBtn || !hideLayoutStyle) && (
        <div className="bottom_section">
          <div className="filters">
            {filters?.map((item: any, i: any) => (
              <React.Fragment key={i}>
                {item.type === "button" && (
                  <div
                    className={classNames("button", {
                      active:
                        activeBtnFilter === item.label ||
                        activeBtnFilter === item.value,
                    })}
                    onClick={() => item.onClick(item)}
                  >
                    {item.label}
                  </div>
                )}

                {item.type === "tag" && (
                  <Tag name={item.label} value={item.value} />
                )}

                {item.type === "select" && (
                  <FormSelect
                    name="filterSelect"
                    defaultValue={item.value}
                    options={item.options}
                    onChange={(value: any) => item.onChange(value)}
                  />
                )}
                {item.type === "searchFilter" && (
                  <div className="search_filter">
                    <FormInput
                      type="text"
                      placeholder={item.label}
                      value={searchTerm}
                      onKeyPress={handleKeyPress}
                      onChange={(e: any) => setSearchTerm(e?.target?.value)}
                      readOnly={isSearching}
                      leftIcon="search"
                    />
                    <DropDown toggler={<img src={funnel} alt="" />}>
                      {item.children}
                    </DropDown>
                  </div>
                )}
                {item.type === "dropSelect" && (
                  <div className="drop_select">
                    {item.variant === "checkbox" ? (
                      <input className="check-input" type={"checkbox"} />
                    ) : (
                      <></>
                    )}
                    <p>{item.label}</p>
                    <DropDown toggler={<Icon name="chevronDown" />}>
                      {item.options?.map((item: any, i: any) => (
                        <div
                          key={i}
                          className="dropdown-item"
                          onClick={item?.action}
                        >
                          {item?.label}
                        </div>
                      ))}
                    </DropDown>
                  </div>
                )}
              </React.Fragment>
            ))}
          </div>

          {hideAction ? (
            <></>
          ) : (
            <div className="actions">
              {actionBtn}

              <div className="layouts">
                {layoutOptions?.map((item: any, i: any) => (
                  <div
                    key={i}
                    className={classNames("item", {
                      active: listLayout === item.icon,
                    })}
                    onClick={item.onClick}
                  >
                    <Icon name={item.icon} />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Topbar;
